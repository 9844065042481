<template>
  <div class="history-container">
    <div class="heard">
      <div class="heartitle">
        <img
          class="companylogin"
          src="../../assets/列表页切图/2-logo@2x.png"
          alt=""
        />

        <div class="titleright">
          <el-input
            class="input"
            placeholder="搜索"
            prefix-icon="el-icon-search"
            v-model="value"
            color="#F3AA19;"
          >
          </el-input>
          <!-- <div class="mylogin">
            <img class="myimg" src="../../assets/首页切图/my.png" alt="" /> -->
            <!-- <span class="logintext">请登录</span> -->
          <!-- </div> -->
        </div>
      </div>
      <div class="heardimg">
        <!-- <img class="img" src="../../assets/空白页切图/bgc@2x.png" alt="" />
        <span>暂时还没收藏记录哦~</span> -->
        <div class="center-box">
          <div class="box">
            <img
              class="v-btn"
              src="../../assets/列表页切图/v-btn@2x.png"
              alt=""
            />
            <img
              class="centimg"
              src="../../assets/列表页切图/centimg@3x.png"
              alt=""
            />
            <div class="botmbox">
              <div class="browse">
                <img
                  class="Browseimg"
                  src="../../assets/列表页切图/liuran@2x.png"
                  alt=""
                />
                <span class="text">1232142</span>
              </div>
              <div class="browses">
                <img
                  class="Browseimg"
                  src="../../assets/列表页切图/dianzan@2x.png"
                  alt=""
                />
                <span class="texts">1232142</span>
              </div>
            </div>
          </div>
          <div class="box">
            <img
              class="v-btn"
              src="../../assets/列表页切图/v-btn@2x.png"
              alt=""
            />
            <img
              class="centimg"
              src="../../assets/列表页切图/centimg@3x.png"
              alt=""
            />
            <div class="botmbox">
              <div class="browse">
                <img
                  class="Browseimg"
                  src="../../assets/列表页切图/liuran@2x.png"
                  alt=""
                />
                <span class="text">1232142</span>
              </div>
              <div class="browses">
                <img
                  class="Browseimg"
                  src="../../assets/列表页切图/dianzan@2x.png"
                  alt=""
                />
                <span class="texts">1232142</span>
              </div>
            </div>
          </div>
          <div class="box">
            <img
              class="v-btn"
              src="../../assets/列表页切图/v-btn@2x.png"
              alt=""
            />
            <img
              class="centimg"
              src="../../assets/列表页切图/centimg@3x.png"
              alt=""
            />
            <div class="botmbox">
              <div class="browse">
                <img
                  class="Browseimg"
                  src="../../assets/列表页切图/liuran@2x.png"
                  alt=""
                />
                <span class="text">1232142</span>
              </div>
              <div class="browses">
                <img
                  class="Browseimg"
                  src="../../assets/列表页切图/dianzan@2x.png"
                  alt=""
                />
                <span class="texts">1232142</span>
              </div>
            </div>
          </div>
          <div class="box">
            <img
              class="v-btn"
              src="../../assets/列表页切图/v-btn@2x.png"
              alt=""
            />
            <img
              class="centimg"
              src="../../assets/列表页切图/centimg@3x.png"
              alt=""
            />
            <div class="botmbox">
              <div class="browse">
                <img
                  class="Browseimg"
                  src="../../assets/列表页切图/liuran@2x.png"
                  alt=""
                />
                <span class="text">1232142</span>
              </div>
              <div class="browses">
                <img
                  class="Browseimg"
                  src="../../assets/列表页切图/dianzan@2x.png"
                  alt=""
                />
                <span class="texts">1232142</span>
              </div>
            </div>
          </div>
          <div class="box">
            <img
              class="v-btn"
              src="../../assets/列表页切图/v-btn@2x.png"
              alt=""
            />
            <img
              class="centimg"
              src="../../assets/列表页切图/centimg@3x.png"
              alt=""
            />
            <div class="botmbox">
              <div class="browse">
                <img
                  class="Browseimg"
                  src="../../assets/列表页切图/liuran@2x.png"
                  alt=""
                />
                <span class="text">1232142</span>
              </div>
              <div class="browses">
                <img
                  class="Browseimg"
                  src="../../assets/列表页切图/dianzan@2x.png"
                  alt=""
                />
                <span class="texts">1232142</span>
              </div>
            </div>
          </div>
          <div class="box">
            <img
              class="v-btn"
              src="../../assets/列表页切图/v-btn@2x.png"
              alt=""
            />
            <img
              class="centimg"
              src="../../assets/列表页切图/centimg@3x.png"
              alt=""
            />
            <div class="botmbox">
              <div class="browse">
                <img
                  class="Browseimg"
                  src="../../assets/列表页切图/liuran@2x.png"
                  alt=""
                />
                <span class="text">1232142</span>
              </div>
              <div class="browses">
                <img
                  class="Browseimg"
                  src="../../assets/列表页切图/dianzan@2x.png"
                  alt=""
                />
                <span class="texts">1232142</span>
              </div>
            </div>
          </div>
          <div class="box">
            <img
              class="v-btn"
              src="../../assets/列表页切图/v-btn@2x.png"
              alt=""
            />
            <img
              class="centimg"
              src="../../assets/列表页切图/centimg@3x.png"
              alt=""
            />
            <div class="botmbox">
              <div class="browse">
                <img
                  class="Browseimg"
                  src="../../assets/列表页切图/liuran@2x.png"
                  alt=""
                />
                <span class="text">1232142</span>
              </div>
              <div class="browses">
                <img
                  class="Browseimg"
                  src="../../assets/列表页切图/dianzan@2x.png"
                  alt=""
                />
                <span class="texts">1232142</span>
              </div>
            </div>
          </div>
          <div class="box">
            <img
              class="v-btn"
              src="../../assets/列表页切图/v-btn@2x.png"
              alt=""
            />
            <img
              class="centimg"
              src="../../assets/列表页切图/centimg@3x.png"
              alt=""
            />
            <div class="botmbox">
              <div class="browse">
                <img
                  class="Browseimg"
                  src="../../assets/列表页切图/liuran@2x.png"
                  alt=""
                />
                <span class="text">1232142</span>
              </div>
              <div class="browses">
                <img
                  class="Browseimg"
                  src="../../assets/列表页切图/dianzan@2x.png"
                  alt=""
                />
                <span class="texts">1232142</span>
              </div>
            </div>
          </div>
          <div class="box">
            <img
              class="v-btn"
              src="../../assets/列表页切图/v-btn@2x.png"
              alt=""
            />
            <img
              class="centimg"
              src="../../assets/列表页切图/centimg@3x.png"
              alt=""
            />
            <div class="botmbox">
              <div class="browse">
                <img
                  class="Browseimg"
                  src="../../assets/列表页切图/liuran@2x.png"
                  alt=""
                />
                <span class="text">1232142</span>
              </div>
              <div class="browses">
                <img
                  class="Browseimg"
                  src="../../assets/列表页切图/dianzan@2x.png"
                  alt=""
                />
                <span class="texts">1232142</span>
              </div>
            </div>
          </div>
          <div class="box">
            <img
              class="v-btn"
              src="../../assets/列表页切图/v-btn@2x.png"
              alt=""
            />
            <img
              class="centimg"
              src="../../assets/列表页切图/centimg@3x.png"
              alt=""
            />
            <div class="botmbox">
              <div class="browse">
                <img
                  class="Browseimg"
                  src="../../assets/列表页切图/liuran@2x.png"
                  alt=""
                />
                <span class="text">1232142</span>
              </div>
              <div class="browses">
                <img
                  class="Browseimg"
                  src="../../assets/列表页切图/dianzan@2x.png"
                  alt=""
                />
                <span class="texts">1232142</span>
              </div>
            </div>
          </div>
          <div class="box">
            <img
              class="v-btn"
              src="../../assets/列表页切图/v-btn@2x.png"
              alt=""
            />
            <img
              class="centimg"
              src="../../assets/列表页切图/centimg@3x.png"
              alt=""
            />
            <div class="botmbox">
              <div class="browse">
                <img
                  class="Browseimg"
                  src="../../assets/列表页切图/liuran@2x.png"
                  alt=""
                />
                <span class="text">1232142</span>
              </div>
              <div class="browses">
                <img
                  class="Browseimg"
                  src="../../assets/列表页切图/dianzan@2x.png"
                  alt=""
                />
                <span class="texts">1232142</span>
              </div>
            </div>
          </div>
          <div class="box">
            <img
              class="v-btn"
              src="../../assets/列表页切图/v-btn@2x.png"
              alt=""
            />
            <img
              class="centimg"
              src="../../assets/列表页切图/centimg@3x.png"
              alt=""
            />
            <div class="botmbox">
              <div class="browse">
                <img
                  class="Browseimg"
                  src="../../assets/列表页切图/liuran@2x.png"
                  alt=""
                />
                <span class="text">1232142</span>
              </div>
              <div class="browses">
                <img
                  class="Browseimg"
                  src="../../assets/列表页切图/dianzan@2x.png"
                  alt=""
                />
                <span class="texts">1232142</span>
              </div>
            </div>
          </div>
          <!-- <div class="pagination">
            <el-pagination background layout="prev, pager, next" :total="1000">
            </el-pagination>
          </div> -->
        </div>
      </div>
    </div>

    <div class="bottom">
      <div class="center-bom">
        <img
          class="botmlogin"
          src="../../assets/列表页切图/logo@2x.png"
          alt=""
        />
        <div class="bomtext">
          <h6>杭州晨星创文网络科技有限公司</h6>
          <span class="spanone"
            >公司地址：浙江省杭州市钱塘新区佳宝科创中心2幢508室</span
          ><br />
          <span class="spantwo">联系电话：0571-61075056</span>
        </div>
        <div class="opcode"></div>
        <div class="boms">Icp备18038160号-3</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "history",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.heard {
  width: 100vw;
  height: 100vh;
  background: url("../../assets/空白页切图/2-bgc@2x (2).png") no-repeat;
  // padding-top: 160px;
  background-size: 100% 100%;
}

.companylogin {
  margin-top: 4%;

  // margin-top: 0px;
}
.heartitle {
  position: relative;
  width: 1148px;
  margin: 0 auto;
  height: 160px;
  // background-color: red;
  // padding-top: 20px;

  .titleright {
    position: absolute;
    top: 30px;
    right: 17px;
    width: 220px;
    height: 84px;
  }
}
/deep/input::-webkit-input-placeholder {
  color: #f3aa19;
  font-weight: 400;
  font-size: 16px;
}
/deep/input::-moz-input-placeholder {
  color: #f3aa19;
  font-size: 16px;
  font-weight: 400;
}
/deep/input::-ms-input-placeholder {
  color: #f3aa19;
  font-size: 16px;
  font-weight: 400;
}
.input {
  width: 146px;
  height: 46px;
  opacity: 0.52;
  /deep/.el-icon-search:before {
    padding-left: 30px;
    color: #f3aa19;
    font-size: 18px;
    line-height: 46px;
  }
  /deep/.el-input__inner {
    border-radius: 20px;
    padding: 20px;
    text-align: center;
    color: #f3aa19;
    line-height: 47px;
  }
}
.mylogin {
  position: absolute;
  top: 0;
  right: 2px;
  width: 48px;
  height: 85px;
  .myimg {
    width: 46px;
    height: 46px;
  }
  .logintext {
    width: 48px;
    height: 16px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
  }
}
.heardimg {
  position: relative;
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  .img {
    position: absolute;
    top: 50%;
    left: 48%;
    transform: translate(-50%, -50%);

    background-size: 100% 100%;
    // background-position: center;
  }
  span {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 20px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #7a7a7a;
  }
}
.center-box {
  width: 1148px;
  height: 920;
  margin: 0 auto;
  height: 100%;
  // background-color: pink;
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;
  padding-bottom: 95px;
}
.box {
  position: relative;
  width: 265px;
  height: 220px;
  background-color: #2ed2f5;
  margin-left: 21px;
  border-radius: 10px;
  margin-top: 41px;
  .v-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 99999;
    transform: translate(-50%, -50%);
    background-size: 100% 100%;
  }
  .centimg {
    position: absolute;
    top: 0;
    left: 0;
    width: 265px;
    height: 230px;
    border-radius: 0px 0px 10px 10px;
  }
  .botmbox {
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 265px;
    height: 46px;
    background: #ffffff;
    border-radius: 0px 0px 10px 10px;
    .browse {
      width: 142px;
      height: 100%;
      // background-color: pink;
      border-radius: 0px 0px 0px 10px;
    }
    .Browseimg {
      position: absolute;
      top: 13px;
      left: 15px;
    }
    .text {
      position: absolute;
      top: 20px;
      left: 85px;
      height: 20px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #c9c9c9;
    }
    .texts {
      position: absolute;
      top: 20px;
      right: 25px;
      height: 20px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #c9c9c9;
    }
    .browses {
      position: absolute;
      top: 0;
      right: 0;
      width: 130px;
      height: 100%;
      border-radius: 0px 0px 10px 0px;
    }
  }
}
.pagination {
  width: 830px;
 margin-left: 14%;
  padding-top: 46px;
}
/deep/.el-pagination {
  text-align: center;
  margin: 10px auto;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ecbf1c;
}
/deep/.el-pager li {
  margin: 0 10px !important;
  width: 40px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #a8a8a8;
  border-radius: 6px;
  line-height: 40px;
  color: #fff;
}
/deep/.number {
  margin: 0 10px !important;
}
/deep/.btn-next {
  width: 40px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #a8a8a8;
  border-radius: 6px;
  line-height: 40px;
  color: #fff;
}
/deep/.btn-prev {
  width: 40px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #a8a8a8;
  border-radius: 6px;
  line-height: 40px;
  color: #fff;
}
/deep/ .el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  text-align: center;
  line-height: 44px;
  border-style: 1px solid;
}
/deep/.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  line-height: 44px;
}

.bottom {
  width: 100vw;
  height: 298px;
  background: #353535;
  .center-bom {
    position: relative;
    width: 1148px;
    height: 100%;
    // background-color: yellow;
    margin: 160px auto;
    .botmlogin {
      position: absolute;
      top: 40px;
      left: 0;
    }
    .bomtext {
      position: absolute;
      top: 70px;
      left: 195px;
      width: 534px;
      height: 110px;
      // background-color: pink;
      h6 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #868686;
      }
      .spanone {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #878787;
      }
      .spantwo {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #878787;
      }
    }
    .opcode {
      position: absolute;
      right: 0;
      top: 50px;
      width: 140px;
      height: 140px;
      background-color: #fff;
    }
    .boms {
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: center;
      width: 100%;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #868686;
    }
  }
}
</style>
